import { isEmpty } from "lodash";
import { HextoRGB } from "./colorConversion";
export const validateObject = (obj: any) => {
  // Check if 'rules' key is empty
  if (!obj.rules || obj.rules.length === 0) {
    return false;
  }

  // Check if all rules meet the criteria
  return obj.rules.every((rule: any) => {
    // Type 1 rule
    if (rule.rules) {
      // Recursively validate Type 1 rule
      return validateObject(rule);
    } else {
      // Type 2 rule
      // Check if 'dataset_fields' key exists and it is an object
      return (
        !!rule.dataset_fields &&
        typeof rule.dataset_fields === "object" &&
        // Check if all values inside 'dataset_fields' are defined
        Object.values(rule.dataset_fields).every((value) => {
          return value !== undefined;
        })
      );
    }
  });
};

export const updateProcessedData = (processedData: any) => {
  if (!isEmpty(processedData)) {
    const updateProcessedData = {
      ...processedData,
      fields: processedData.fields?.map((c: any) => {
        const name_update = c.name.split("<sep>")[0];
        return {
          ...c,
          name: name_update,
        };
      }),
    };
    return updateProcessedData;
  } else {
    return updateProcessedData;
  }
};

export const buildLegendData = (config: any, processedData: any) => {
  const legendData: any = {
    title: config.title,
    visible: config.visible,
    colorBasedOn: config.colorBasedOn,
    color: config.colorRange,
    customColor: config?.customColor?.map((item: any) => HextoRGB(item)),
    radiusBasedOn: config.radiusBasedOn,
    heightBasedOn: config.heightBasedOn,
    strokeWidthBasedOn: config.strokeWidthBasedOn,
    strokeColorBasedOn: config.strokeColorBasedOn,
    strokeColor: config.strokeColorRange,
    customStrokeColor: config?.customStrokeColor?.map((item: any) =>
      HextoRGB(item)
    ),
    lineColor: config.lineColor,
    fillColor: config.fillColor,
    wireframe: config.wireframe,
    stroked: config.stroked,
    fillColorScaleType: config.colorScale,
    strokeColorScaleType: config.strokeColorScale,
    layerId: config.layerId,
    fillCondition: config.fillCondition,
    fillConditionEnabled:
      typeof config?.fillConditionEnabled === "boolean"
        ? config?.fillConditionEnabled
        : config?.conditionEnabled,
    strokeConditionEnabled:
      typeof config?.strokeConditionEnabled === "boolean"
        ? config?.strokeConditionEnabled
        : false,
    strokeCondition: config.strokeCondition ?? undefined,
    nullConfig: config.nullConfig,
    processedData: updateProcessedData(processedData),
  };
  return legendData;
};

export const layerOrder = (layers: any) => {
  const baseLayers =
    layers &&
    layers
      .filter((layer: any) => layer.props.category === "base")
      .sort((a: any, b: any) => a.props.priority - b.props.priority);
  const polygonLayers =
    layers &&
    layers
      .filter((layer: any) => layer.props.category === "polygon")
      .sort((a: any, b: any) => a.props.priority - b.props.priority);
  const markersLayers =
    layers &&
    layers
      .filter((layer: any) => layer.props.category === "markers")
      .sort((a: any, b: any) => a.props.priority - b.props.priority);
  const finalLayers = [...baseLayers, ...polygonLayers, ...markersLayers];
  return finalLayers;
};

interface Dataset {
  dataset_id: string;
  display_name: string;
  asset_type: string;
}

interface RecommendedDatasets {
  primary?: Dataset[];
  secondary?: Dataset[];
}

interface AssetDetail {
  asset: string;
  upload_dataset: string[];
  recommended_datasets?: RecommendedDatasets;
}

export const combineRecommendedDatasets = (
  assetDetails: AssetDetail[]
): Dataset[] => {
  const combinedDatasets: Dataset[] = [];

  assetDetails.forEach((asset) => {
    const asset_type = asset.asset;
    const recommended = asset.recommended_datasets;

    if (recommended) {
      const primary = recommended.primary || [];
      const secondary = recommended.secondary || [];

      // Combine primary and secondary datasets
      const datasets = [...primary, ...secondary];

      // Add each dataset to the combined list
      datasets.forEach((dataset) => {
        combinedDatasets.push({
          dataset_id: dataset.dataset_id,
          display_name: dataset.display_name,
          asset_type: asset_type,
        });
      });
    }
  });

  return combinedDatasets;
};
