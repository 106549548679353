import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  globalModel,
  userModal,
  dashboardModel,
  deckglModel,
  reportModel,
  accessModel,
  displayEntityModel,
  analyticsModel,
  scenariosModel,
} from "./model";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "user",
    "deckgl",
    "dashboard",
    "reports",
    "access",
    "global",
    "displayEntity",
    "scenarios",
  ],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_FIREBASE_API_KEY || "ai_tl_secretKey",
      onError: (err) => {
        console.log(err);
      },
    }),
  ],
};

const userPersistConfig = {
  key: "user",
  storage,
  blacklist: ["notification", "data", "uploadData"],
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_FIREBASE_API_KEY || "ai_tl_secretKey",
      onError: (err) => {
        console.log(err);
      },
    }),
  ],
};

const reducers = combineReducers({
  user: persistReducer(userPersistConfig, userModal),
  dashboard: dashboardModel,
  deckgl: deckglModel,
  reports: reportModel,
  global: globalModel,
  access: accessModel,
  displayEntity: displayEntityModel,
  analytics: analyticsModel,
  scenarios: scenariosModel,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const HEAVY_ACTIONS = [
  "global/setFilteredLayerValues",
  "deckgl/setDeckLayers",
  "deckgl/addOrUpdateDeckLayer",
  "deckgl/removeDeckLayer",
  "deckgl/setLegendData",
];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(),
  devTools:
    process.env.NODE_ENV === "development"
      ? {
          actionSanitizer: (action: any) => {
            switch (true) {
              case HEAVY_ACTIONS.includes(action.type):
                return typeof action.payload !== "undefined"
                  ? { ...action, payload: "<<LONG_BLOB>>" }
                  : { ...action, results: "<<LONG_BLOB>>" };
              default:
                return action;
            }
          },
          stateSanitizer: (state: any) => {
            const sanitizedGlobal = {
              ...state.global,
              portfolioBuildingLayer: state.global.portfolioBuildingLayer
                ? "<<LONG_BLOB>>"
                : state.global.portfolioBuildingLayer,
              filteredLayerValues: state.global.filteredLayerValues
                ? "<<LONG_BLOB>>"
                : state.global.filteredLayerValues,
            };
            const sanitizedDeckgl = {
              ...state.deckgl,
              deckLayers: state.deckgl.deckLayers
                ? "<<LONG_BLOB>>"
                : state.deckgl.deckLayers,
              legendData: state.deckgl.legendData
                ? "<<LONG_BLOB>>"
                : state.deckgl.legendData,
            };
            return {
              ...state,
              global: sanitizedGlobal,
              deckgl: sanitizedDeckgl,
            };
          },
        }
      : false,
});

export const persistor = persistStore(store);
export default store;
