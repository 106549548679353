import "./App.css";
import "./components/components.css";
import "./components/media@1240.css";
import {Layout, Spin} from "antd";
import {lazy, Suspense, useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {hotjar} from "react-hotjar";
import MainMenu from "./components/common/MainMenu";
import PrivateRoute from "./components/common/PrivateRoute";
import AdminRoute from "./components/common/AdminRoute";
import {axiosUnAuth} from "./hooks/useAxios";
import {errorPrompt} from "./components/common/Prompt";
import {ThemeTypes, useThemeStore} from "./stores/ThemeStore";
import {isEmpty} from "lodash";
import TermsAndConditions from "./pages/TermsAndConditions";
import DsoRoute from "./components/common/DsoRoute";
import useFeatureFlags from "./hooks/useFeatureFlags";
import {useDispatch, useSelector} from "react-redux";
import {StateTypes} from "./model/data";
import {setFeatureFlags} from "./model";
// import { assert } from "superstruct";
// import { BrandingDataTypes, FeatureDataTypes } from "./pages/superStructTypes/loginStructType";
// pages import
const MapStudio = lazy(() => import("./pages/MapStudio"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Reports = lazy(() => import("./pages/Reports"));
const Access = lazy(() => import("./pages/Access"));
const Stakeholder = lazy(() => import("./pages/Stakeholder"));
const DsoDashboard = lazy(() => import("./pages/DsoDashboard"));
const PublicDataStories = lazy(
  () => import("./pages/PublicDataStories/PublicDataStory")
);
const MasterPlan = lazy(() => import("./components/masterplan/MasterPlanMain"));

const ConsultationResponse = lazy(
  () => import("./components/stake-holder/ConsultationResponse")
);

const NoPage = lazy(() => import("./pages/NoPage"));
const Auth = lazy(() => import("./pages/Authentication/Auth"));
const AuthIndex = lazy(() => import("./pages/Authentication/AuthIndex"));
const Resources = lazy(() => import("./pages/Resources"));
const ForecastsandPlans = lazy(() => import("./pages/ForecastsandPlans"));
const ProgressTracker = lazy(() => import("./pages/ProgressTracker"));
const PlanningProgress = lazy(() => import("./pages/PlanningProgress"));
const DataStories = lazy(() => import("./pages/DataStories"));
const StoryEditor = lazy(() => import("./components/stories/StoryEditor"));
// const AnalyticsPage = lazy(() => import("./pages/AnalyticsPage"));
const ScenariosPage = lazy(() => import("./pages/ScenariosPage"));
const ScenariosPageUkpn = lazy(() => import("./pages/ScenarioPageUkpn"));
const ScenariosOutputsPage = lazy(
  () => import("./components/scenarios/components/ScenarioOutputDrawer")
);
const PortFolio = lazy(() => import("./pages/PortFolio"));
const MyPortfolio = lazy(() => import("./components/portfolio/MyPortfolio"));

const {Content} = Layout;
const dno = `${process.env.REACT_APP_DNO_NAME}`;

function App() {
  const setTheme = useThemeStore((s: ThemeTypes) => s.setTheme);
  const [themeLoading, setThemeLoading] = useState<boolean>(false);
  const [featureLoading, setFeatureLoading] = useState<boolean>(false);
  const [favIcon, setFavIcon] = useState<string>("");
  const [pageTitle, setPageTitle] = useState<string>("");
  const axios = axiosUnAuth();
  const dispatch = useDispatch();
  const features = useFeatureFlags();

  useEffect(() => {
    if (
      process.env.REACT_APP_HOTJAR_HJID &&
      process.env.REACT_APP_HOTJAR_HJSV
    ) {
      hotjar.initialize(
        Number(process.env.REACT_APP_HOTJAR_HJID),
        //@ts-ignore
        Number(process.env.REACT_APP_HOTJAR_HJSV)
      );
    }
  }, []);

  const collapsed = useSelector(
    (state: StateTypes) => state.global.mainSidebarVisible
  );

  const handleBranding = (data: any) => {
    const {
      metadata: {branding, iconography},
    } = data;
    const stage = process.env.REACT_APP_STAGE;
    const obj: Record<string, string> = {
      "--primary-background": branding.primary,
      "--secondary-background": branding.secondary,
      "--sidebar-background-color": branding.sidebar,
      "--default-cta": branding.cta,
    };
    Object.keys(obj).forEach((item) => {
      document.documentElement.style.setProperty(item, obj[item]);
    });

    Object.values(iconography).forEach((item: any) => {
      Object.values(item).forEach((elm: any) => {
        elm.icon = elm.icon.replace("{stage}", stage);
      });
    });
    setTheme(data.metadata);
    setFavIcon(branding["fav-icon"]);
    setPageTitle(branding["title"]);
  };

  useEffect(() => {
    (async () => {
      try {
        setThemeLoading(true);
        const {
          data: {data},
        } = await axios.get(`${process.env.REACT_APP_GET_BRANDING}`);
        // Commented for sit rollback
        // assert(data, BrandingDataTypes)
        handleBranding(data);
      } catch (err: any) {
        // const { value, type, path } = err;
        errorPrompt({
          // message: `API value got changed at ${path}, expecting ${type} instead of ${typeof (value)} ${value ? value : ""}` || "Could not get branding details",
          message: "Could not get branding details",
        });
      } finally {
        setThemeLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);

  // Getting feature flags to configure enable/disable feature
  useEffect(() => {
    (async () => {
      setFeatureLoading(true);
      try {
        const {
          data: {data},
        } = await axios.get(`${process.env.REACT_APP_FEATURE_FLAGS}`);
        // Commented for sit rollback
        // assert(data, FeatureDataTypes);
        dispatch(setFeatureFlags(data));
      } catch (err: any) {
        // const { value, type, path } = err;
        // errorPrompt({ message: `API value got changed at ${path}, expecting ${type} instead of ${typeof (value)} ${value ? value : ""}` || "Could not get feature flags" });
        errorPrompt({message: "Could not get feature flags"});
      } finally {
        setFeatureLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(favIcon)) {
      const link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = favIcon;
      document.head.appendChild(link);
    }
    // return () => {
    //   document.head.removeChild(link);
    // };
  }, [favIcon]);

  useEffect(() => {
    if (!isEmpty(pageTitle)) {
      const title: any = pageTitle;
      document.title = title;
    }
    // return () => {
    //   document.head.removeChild(link);
    // };
  }, [pageTitle]);

  const userLoggedIn = useSelector((state: StateTypes) => state.user.loggedIn);

  //
  // ------------- Intercom feature Enable/Disable based on DNO access
  //
  useEffect(() => {
    window.Intercom("update", {
      hide_default_launcher: !features?.intercom,
    });
  }, [features]);

  if (themeLoading || featureLoading)
    return (
      <Spin
        size="large"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
    );

  return (
    <Router>
      <Suspense
        fallback={
          <Spin
            size="large"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          />
        }
      >
        <Switch>
          <Route path="/" exact component={Auth} />
          <Route path="/auth/:page" exact component={AuthIndex} />
          <Route path={"/pub/consultation"} component={ConsultationResponse} />
          <Route path={"/pub/data-story"} component={PublicDataStories} />
          <>
            {userLoggedIn && <TermsAndConditions />}
            <Layout>
              {userLoggedIn && <MainMenu />}
              <Layout style={{height: "100vh"}}>
                <Content
                  className="content"
                  id="content"
                  style={{
                    paddingLeft: collapsed ? "90px" : "240px",
                  }}
                >
                  <Switch>
                    <PrivateRoute
                      path="/la/:laName/home"
                      exact
                      component={Dashboard}
                      feature={true}
                    />
                    <PrivateRoute
                      path="/la/:laName/map/:mapId"
                      exact
                      component={MapStudio}
                      feature={features?.map}
                    />

                    <PrivateRoute
                      path="/la/:laName/dashboard"
                      component={Reports}
                      feature={features?.dashboards}
                    />
                    <AdminRoute
                      path="/la/:laName/access"
                      component={Access}
                      feature={features?.["manage-access"]}
                    />
                    <PrivateRoute
                      path={[
                        "/la/:laName/stakeholder-engagement",
                        "/la/:laName/stakeholder-engagement/consultation",
                        "/la/:laName/stakeholder-engagement/consultation/:id",
                        "/la/:laName/stakeholder-engagement/view/:id",
                        "/la/:laName/stakeholder-engagement/view-consultation/:id",
                      ]}
                      exact
                      component={Stakeholder}
                      feature={features?.["stakeholder-engagement"]}
                    />
                    <PrivateRoute
                      path="/la/:laName/resources"
                      exact
                      component={Resources}
                      feature={[
                        features?.guidance,
                        features?.["use-cases"],
                        features?.["news-and-events"],
                      ].includes(true)}
                    />
                    <PrivateRoute
                      path="/la/:laName/shareplans"
                      exact
                      component={ForecastsandPlans}
                      feature={features?.["our-forecasts-and-your-plans"]}
                    />
                    <PrivateRoute
                      path="/la/:laName/planning-progress"
                      exact
                      component={PlanningProgress}
                      feature={features?.["planning-status"]}
                    />
                    <PrivateRoute
                      path="/la/:laName/progress-tracker"
                      exact
                      component={ProgressTracker}
                      feature={features?.["progress-tracker"]}
                    />
                    {/* <PrivateRoute
                        path="/analytics"
                        exact
                        component={AnalyticsPage}
                      /> */}
                    <PrivateRoute
                      path={[
                        "/la/:laName/portfolios",
                        "/la/:laName/portfolios/create",
                        "/la/:laName/portfolios/:id/edit",
                      ]}
                      exact
                      component={PortFolio}
                      feature={features?.portfolios}
                    />
                    <PrivateRoute
                      path={["/la/:laName/portfolios/:id/results"]}
                      exact
                      component={MyPortfolio}
                      feature={features?.portfolios}
                    />
                    <PrivateRoute
                      path={[
                        "/la/:laName/scenarios",
                        "/la/:laName/scenarios/create",
                        "/la/:laName/scenarios/:id/edit",
                      ]}
                      exact
                      component={
                        dno === "ukpn" ? ScenariosPageUkpn : ScenariosPage
                      }
                      feature={features?.scenarios}
                    />
                    <PrivateRoute
                      path="/la/:laName/scenarios/:id"
                      exact
                      component={ScenariosOutputsPage}
                      feature={features?.scenarios}
                    />
                    <PrivateRoute
                      path="/la/:laName/stories-editor/:id"
                      exact
                      component={StoryEditor}
                      feature={features?.["data-stories"]}
                    />
                    <PrivateRoute
                      path="/la/:laName/data-stories/:id"
                      exact
                      component={DataStories}
                      feature={features?.["data-stories"]}
                    />
                    <PrivateRoute
                      path="/la/:laName/masterplan"
                      exact
                      component={MasterPlan}
                      feature={features?.["master-plan"]}
                    />
                    {/* Dso Dashboard page goes here */}
                    <DsoRoute
                      path={"/dso-dashboard"}
                      // exact
                      component={DsoDashboard}
                      feature={features?.dso}
                    />
                    <Route path="*" component={NoPage} />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </>
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
