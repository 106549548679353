import { isArray, isEmpty } from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { assetsIcon } from "../constants/assetIcons";
import { shallow } from "zustand/shallow";
import appConfig from "../constants/appConfig.json";
const dno = `${process.env.REACT_APP_DNO_NAME}`;

const {
  EVChargePointIcon,
  PhotovoltaicsIcon,
  HomeIcon,
  CableIcon,
  AirSourceHeatPumpIcon,
  GroundSourceHeatPumpIcon,
} = assetsIcon;

export const iconList = {
  "12sq3": {
    title: "EV Charger",
    icon: EVChargePointIcon,
    type: "ev",
    capacity: 3,
  },
  "12sq4": {
    title: "Photovoltaics",
    icon: PhotovoltaicsIcon,
    type: "pv",
    capacity: 7,
  },
  ewewf: {
    title: "Cable",
    icon: CableIcon,
    type: "cable",
    capacity: 0,
  },

  "12sq6": {
    title: "Domestic",
    icon: HomeIcon,
    type: "domestic",
    assets: [],
    capacity: 23,
  },
  nfeni: {
    title: "Air Source Heat Pump",
    icon: AirSourceHeatPumpIcon,
    type: "ashp",
    capacity: 6,
  },
  "12sq7": {
    title: "Ground Source Heat Pump",
    icon: GroundSourceHeatPumpIcon,
    type: "gshp",
    capacity: 6,
  },
  ply12: {
    title: "Building Selection",
    type: "polygon",
    assetId: "ply02",
    assets: [],
  },
};

export type ProjectTypes = {
  title: string;
  desc: string;
  datasets?: {
    datasets_selected: any[];
  };
  proposed_date: any[];
  data: Record<string, any>;
  mode: string;
  selectedFeatureIndexes: number[];
  access: {
    dno: string;
    can_read: boolean;
    can_edit: boolean;
  };
  carbon_template_id?: number | string;
  cost_template_id?: number | string;
  carbon_template_name?: undefined | string;
  cost_template_name?: undefined | string;
  master_plan_exists?: boolean;
  is_feedback_available?: boolean;
  is_feedback_viewed?: boolean;
};

export type ProjectStateTypes = {
  assets: Record<string, any>;
  assetDetails: Record<string, any>;
  assetDatasets: Record<string, any>;
  selectedAsset: Record<string, any> | null;
  assetsProp: any[];
  cpValue: number;
  aggregatedIconLayer: any;
  projectNetworkLayer: any;
  projectBuildingLayer: any;
  projectLayers: any;
  projects: Record<any, ProjectTypes>;
  currentProject: string | number | null;
  selectedProjects: string[];
  buildingProps: any[];
  polygonBuildings: any[];
  showBuildingInfo: boolean;
  assetErrorInfo: {};
  editProjectInfo: boolean;
  projectModalVisible: boolean;
  highlightBuildingIds: Record<string, any>;
  activeSideTab: string;
  budgetEstimate: Record<string, any>;
  costDetails: any;
  networkCost: any;
  questionnaireVisible: boolean;
  shareModalVisible: boolean;
  templateShareModalVisible: boolean;
  capacityConfig: Record<string, any>;
  networkType: Record<string, any>;
  result: Record<string, any>;
  showResult: boolean;
  templateDetail: {
    visible: boolean;
    type: string | undefined;
    edit: boolean;
    id?: number | undefined;
  };
  shareDno: any;
  viewAssessment: boolean;
  capacityLoad: Record<string, any>;
  summaryData: any[] | null;
  capacityLoadPopup: Record<string, any>;
  isPortfolio: boolean; // Portfolio flag for the portfolios projects drawer view
  isMasterPlan: boolean;
  snappingTargets: any[];
  portfolioProjectInfo: any;
  assetDataState: {
    [key: string]: {
      checked: boolean;
      loading: boolean;
      loaded: boolean;
      layer?: any;
    };
  };
  powerFlowResult:{
    type:string,
    data:any[]
  },
  powerFlowPopup:Record<string, any>,
  setCpValue: (data: number) => void;
  setAssetDetails: (data: any) => void;
  setAssetDatasets: (type: string, data: any) => void;
  setActiveSideTab: (data: string) => void;
  setProjects: (data: any) => void;
  setSelectedProject: (id: string) => void;
  addProject: (id: string | number, data: any) => void;
  updateProject: (data: any, id?: string | number) => void;
  updateAssetsProp: (data: any) => void;
  setCurrentProject: (id: string | number | null) => void;
  setProjectLayers: (data: any) => void;
  setAssets: (data: any) => void;
  setAggregatedIconLayer: (data: any) => void;
  setSelectedAsset: (obj: Record<string, any> | null) => void;
  setProjectNetworkLayer: (data: any) => void;
  setProjectBuildingLayer: (data: any) => void;
  setShowBuildingInfo: (val: boolean) => void;
  setBuildingProps: (data: any, type?: string) => void;
  setPolygonBuildings: (data: any) => void;
  setAssetErrorInfo: (data: any) => void;
  setProjectModalVisible: (data: boolean) => void;
  setEditProjectInfo: (data: boolean) => void;
  updateHighlightBuildingIds: (data: any) => void;
  setBudgetEstimate: (data: any) => void;
  setQuestionnaireVisible: (data: boolean) => void;
  setShareModalVisible: (data: boolean) => void;
  setTemplateShareModalVisible: (data: boolean) => void;
  setCostDetails: (data: any) => void;
  setNetworkCost: (data: any) => void;
  setCapacityConfig: (data: any) => void;
  setNetworkType: (data: string) => void;
  resetProjects: () => void;
  setResult: (data: any) => void;
  setShowResult: (data: boolean) => void;
  setTemplateDetail: (data: any) => void;
  setShareDno: (data: any) => void;
  setViewAssessment: (data: boolean) => void;
  setCapacityLoad: (data: any) => void;
  setSummaryData: (data: any) => void;
  setCapacityLoadPopup: (data: any) => void;
  setIsPortfolio: (data: boolean) => void;
  setIsMasterplan: (data: boolean) => void;
  setSnappingTargets: (data: boolean) => void;
  resetProject: () => void;
  setPortfolioProjectInfo: (data: any, id: any) => void;
  updatePortfolioProjectInfo: (data: any, id: any) => void;
  setAssetDataState: (id: string, data: any) => void;
  setPowerFlowResult:(data:any)=>void
  setPowerFlowPopup:(data:any)=>void,
};

const initialProjectState: Partial<ProjectTypes> = {
  data: {
    type: "FeatureCollection",
    features: [],
  },
  mode: undefined,
  selectedFeatureIndexes: [],
};

export const initialState: Partial<ProjectStateTypes> = {
  assets: dno === "ssen" && appConfig?.dfes_on_project ? {} : iconList,
  assetDetails: {},
  assetDatasets: {
    ev: [],
    pv: [],
    ashp: [],
    gshp: [],
  },
  cpValue: 0,
  selectedAsset: null,
  assetsProp: [],
  projectLayers: [],
  aggregatedIconLayer: [],
  projectNetworkLayer: undefined,
  projectBuildingLayer: undefined,
  projects: {},
  currentProject: null,
  selectedProjects: [],
  buildingProps: [],
  polygonBuildings: [],
  showBuildingInfo: false,
  assetErrorInfo: {},
  highlightBuildingIds: {},
  budgetEstimate: {},
  shareModalVisible: false,
  templateShareModalVisible: false,
  costDetails: {},
  networkCost: {},
  activeSideTab: "item-1",
  capacityConfig: {},
  networkType: {},
  showResult: false,
  result: {
    type: "success",
    data: [],
  },
  templateDetail: {
    visible: false,
    type: undefined,
    edit: true,
  },
  shareDno: {},
  viewAssessment: false,
  capacityLoad: {},
  summaryData: null,
  capacityLoadPopup: {},
  snappingTargets: [],
  portfolioProjectInfo: {},
  assetDataState: {},
  powerFlowResult:{
    type:"success",
    data:[]
  }
};

const HEAVY_ACTIONS = [
  "projects/setProjects",
  "projects/setProjectNetworkLayer",
  "projects/setProjectBuildingLayer",
  "projects/setProjectLayers",
  "projects/setSnappingTargets",
  "projects/setAggregatedIconLayer",
  "projects/updateAssetsProp",
  "projects/setAssetDataState",
];

const store = (set: any) => ({
  ...initialState,
  setProjects: (payload: any) => {
    set(
      () => ({
        projects: payload,
      }),
      false,
      "projects/setProjects"
    );
  },
  setSelectedProject: (id: string) => {
    set((state: ProjectStateTypes) => {
      const { selectedProjects } = state;
      const res = selectedProjects.includes(id)
        ? selectedProjects.filter((item) => item !== id)
        : [...selectedProjects, id];
      return {
        selectedProjects: res,
      };
    });
  },
  setActiveSideTab: (data: string) => {
    set(() => ({
      activeSideTab: data,
    }));
  },
  addProject: (id: string | number, data: any) => {
    set((state: ProjectStateTypes) => {
      const newProjects = {
        ...state.projects,
        [id]: data,
      };
      return { projects: newProjects, currentProject: id };
    });
  },
  updateProject: (data: any, id?: string | number) => {
    set(
      (state: ProjectStateTypes) => {
        const { currentProject, projects } = state;
        const updatedProject = {
          ...projects,
          [id ?? currentProject!]: {
            ...projects[id ?? currentProject!],
            ...data,
          },
        };
        return { projects: updatedProject };
      },
      false,
      "projects/updateProject"
    );
  },
  resetProject: () => {
    set((state: ProjectStateTypes) => {
      const { currentProject, projects } = state;
      const updatedProject = {
        ...projects,
        [currentProject!]: {
          ...projects[currentProject!],
          ...initialProjectState,
        },
      };
      return { projects: updatedProject };
    });
  },
  setCurrentProject: (id: string | number | null) => {
    set(() => ({
      currentProject: id,
    }));
  },
  setProjectLayers: (data: any) => {
    set(() => ({ projectLayers: data }), false, "projects/setProjectLayers");
  },
  setAggregatedIconLayer: (data: any) => {
    set(
      () => ({ aggregatedIconLayer: data }),
      false,
      "projects/setAggregatedIconLayer"
    );
  },
  setCpValue: (data: number) => {
    set(() => ({ cpValue: data }));
  },
  setAssets: (data: any) => {
    set(() => ({
      assets: data,
    }));
  },
  setAssetDetails: (data: any) => {
    set(() => ({
      assetDetails: data,
    }));
  },
  setAssetDatasets: (type: string, data: any) => {
    set((s: ProjectStateTypes) => ({
      assetDatasets: {
        ...s.assetDatasets,
        [type]: data,
      },
    }));
  },
  setSelectedAsset: (obj: Record<string, any> | null) => {
    set(() => ({
      selectedAsset: obj,
    }));
  },
  updateAssetsProp: (data: any) => {
    set(
      (state: ProjectStateTypes) => {
        if (isArray(data)) {
          return { assetsProp: data };
        } else
          return {
            assetsProp: [...state.assetsProp, data],
          };
      },
      false,
      "projects/updateAssetsProp"
    );
  },
  setProjectNetworkLayer: (data: any) => {
    set(
      () => ({
        projectNetworkLayer: data,
      }),
      false,
      "projects/setProjectNetworkLayer"
    );
  },
  setProjectBuildingLayer: (data: any) => {
    set(
      () => ({
        projectBuildingLayer: data,
      }),
      false,
      "projects/setProjectBuildingLayer"
    );
  },
  setBuildingProps: (data: any, type?: string) => {
    set(
      (state: ProjectStateTypes) => {
        if (type === "new") {
          return { buildingProps: [...state.buildingProps, data] };
        } else return { buildingProps: data };
      },
      false,
      "projects/setBuildingProps"
    );
  },
  setShowBuildingInfo: (val: boolean) => {
    set(() => ({ showBuildingInfo: val }));
  },
  setPolygonBuildings: (data: any) => {
    set(
      (state: any) => ({
        polygonBuildings: !isEmpty(state.polygonBuildings)
          ? [...state.polygonBuildings, data]
          : data,
      }),
      false,
      "projects/setPolygonBuildings"
    );
  },
  setAssetErrorInfo: (data: any) => {
    set(() => ({ assetErrorInfo: data }));
  },
  setProjectModalVisible: (data: boolean) => {
    set(() => ({ projectModalVisible: data }));
  },
  setEditProjectInfo: (data: boolean) => {
    set(() => ({ editProjectInfo: data }));
  },
  updateHighlightBuildingIds: (data: any) => {
    set(
      () => ({
        highlightBuildingIds: data,
      }),
      false,
      "projects/updateHighlightBuildingIds"
    );
  },
  setBudgetEstimate: (data: any) => {
    set(() => ({
      budgetEstimate: data,
    }));
  },
  setQuestionnaireVisible: (data: any) => {
    set(() => ({
      questionnaireVisible: data,
    }));
  },
  setCostDetails: (data: any) => {
    set(() => ({
      costDetails: data,
    }));
  },
  setNetworkCost: (data: any) => {
    set(() => ({
      networkCost: data,
    }));
  },
  resetProjects: () => {
    set(initialState);
  },
  setShareModalVisible: (data: any) => {
    set(() => ({
      shareModalVisible: data,
    }));
  },
  setTemplateShareModalVisible: (data: any) => {
    set(() => ({
      templateShareModalVisible: data,
    }));
  },
  setCapacityConfig: (data: any) => {
    set(() => ({
      capacityConfig: data,
    }));
  },
  setNetworkType: (data: string) => {
    set(() => ({
      networkType: data,
    }));
  },
  setResult: (data: any) => {
    set(() => ({
      result: data,
    }));
  },
  setShowResult: (data: boolean) => {
    set(() => ({
      showResult: data,
    }));
  },
  setTemplateDetail: (data: any) => {
    set((s: ProjectStateTypes) => ({
      templateDetail: { ...s.templateDetail, ...data },
    }));
  },
  setShareDno: (data: any) => {
    set(() => ({
      shareDno: data,
    }));
  },
  setViewAssessment: (data: boolean) => {
    set(() => ({
      viewAssessment: data,
    }));
  },
  setCapacityLoad: (data: boolean) => {
    set(() => ({
      capacityLoad: data,
    }));
  },
  setSummaryData: (data: any) => {
    set(() => ({
      summaryData: data,
    }));
  },
  setCapacityLoadPopup: (data: boolean) => {
    set(() => ({
      capacityLoadPopup: data,
    }));
  },
  setIsPortfolio: (data: boolean) => {
    set(() => ({
      isPortfolio: data,
    }));
  },
  setIsMasterplan: (data: boolean) => {
    set(() => ({
      isMasterPlan: data,
    }));
  },
  setSnappingTargets: (data: boolean) => {
    set(
      () => ({
        snappingTargets: data,
      }),
      false,
      "projects/setSnappingTargets"
    );
  },
  setPortfolioProjectInfo: (data: any, id: any) => {
    // set(() => ({
    //   portfolioProjectInfo: data
    // }), false, "projects/setPortfolioProjectInfo")
    set(
      (state: ProjectStateTypes) => {
        const updatedPortfolioProjectInfo = {
          ...state.portfolioProjectInfo,
          [id]: data,
        };
        return { portfolioProjectInfo: updatedPortfolioProjectInfo };
      },
      false,
      "projects/setPortfolioProjectInfo"
    );
  },
  updatePortfolioProjectInfo: (data: any, id: any) => {
    set(
      (state: ProjectStateTypes) => {
        const updatedPortfolioProjectInfo = {
          ...state.portfolioProjectInfo,
          [id]: data,
        };
        return { portfolioProjectInfo: updatedPortfolioProjectInfo };
      },
      false,
      "projects/updatePortfolioProjectInfo"
    );
  },
  setAssetDataState: (id: string, data: any) => {
    set(
      (s: ProjectStateTypes) => {
        const res = {
          ...s.assetDataState,
          [id]: {
            ...s.assetDataState[id],
            ...data,
          },
        };
        return { assetDataState: res };
      },
      false,
      "projects/setAssetDataState"
    );
  },
  setPowerFlowResult: (data: any) => {
    set(() => ({
      powerFlowResult: data,
    }));
  },
  setPowerFlowPopup: (data: any) => {
    set(() => ({
      powerFlowPopup: data,
    }));
  },
});

const options = {
  name: "projects",
  enabled: process.env.NODE_ENV === "development",
  actionSanitizer: (action: any) => {
    switch (true) {
      case action.type.includes(HEAVY_ACTIONS):
        return typeof action.payload !== "undefined"
          ? { ...action, payload: "<<LONG_BLOB>>" }
          : { ...action, results: "<<LONG_BLOB>>" };
      default:
        return action;
    }
  },
  stateSanitizer: (state: any) => ({
    ...state,
    projects: "<<LONG_BLOB>>",
    projectNetworkLayer: "<<LONG_BLOB>>",
    projectBuildingLayer: "<<LONG_BLOB>>",
    projectLayers: "<<LONG_BLOB>>",
    snappingTargets: "<<SNAP_TARGET_DATA>>",
    aggregatedIconLayer: "<<LONG_BLOB>>",
    assetsProp: "<<LONG_BLOB>>",
    assetDataState: "<<LONG_BLOB>>",
  }),
};

const useStore = create<Partial<ProjectStateTypes>>()(
  devtools((set) => store(set), options)
);

export default function useProjectStore<U>(selector: (state: any) => U) {
  return useStore(selector, shallow);
}
