import { Button, Checkbox, Modal, Space, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";
import { axiosUnAuth } from "../hooks/useAxios";
import { errorPrompt } from "../components/common/Prompt";
import { clearDeckState, clearUser, queryActivePage } from "../model";
import { batch, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import useProjectStore, { ProjectStateTypes } from "../stores/ProjectStore";
import { Document, Page, pdfjs } from "react-pdf";
import { StateTypes } from "../model/data";
import {
  setIsTermAndConditionVisible,
  setTermsAccepted,
} from "../model/dashboard";
import {
  GetPresignedUrlData,
  IsTermsAndConditionsAccepted,
} from "../global/globalApi";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ pdfContent }: any) => {
  const [numPages, setNumPages] = useState<{ [key: number]: number }>({});
  const [loadedPages, setLoadedPages] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [loading, setLoading] = useState(true);

  const onDocumentLoadSuccess = (index: number, { numPages }: any) => {
    setNumPages((prev) => ({ ...prev, [index]: numPages }));
    setLoadedPages((prev) => ({ ...prev, [index]: true }));
  };

  useEffect(() => {
    if (pdfContent && pdfContent.length > 0) {
      setLoading(false);
    } else {
      setLoading(true)
    }
  }, [pdfContent]);

  const dno = process.env.REACT_APP_DNO_NAME as string;

  return (
    <>
      {loading ? (
        <Spin
          size="large"
          tip="Please wait..."
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : (
        pdfContent.map((item: any, i: number) => (
          <Document
            key={`document_${i}`}
            file={item}
            onLoadSuccess={(pdf) => onDocumentLoadSuccess(i, pdf)}
            loading={null}
          >
            {loadedPages[i] &&
              Array.from(new Array(numPages[i]), (_, index) => (
                <Page
                  className={
                    dno === "laep" ? "pdf-doc-style-laep" : "pdf-doc-style"
                  }
                  loading={null}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  width={680}
                  key={`page_${i}_${index}`}
                  pageNumber={index + 1}
                  renderMode="canvas"
                />
              ))}
          </Document>
        ))
      )}
    </>
  );
};

const TermsAndConditions = () => {
  const resetProjects = useProjectStore(
    (s: ProjectStateTypes) => s.resetProjects
  );
  const isOpenTermAndCondition = useSelector(
    (state: StateTypes) => state.dashboard.isTermAndConditionVisible
  );

  const termsAccepted = useSelector(
    (state: StateTypes) => state.dashboard.termsAccepted
  );

  const la = JSON.parse(
    window.sessionStorage.getItem("local_authority") ?? "{}"
  );

  const [agreeTnc, setAgreeTnc] = useState<boolean>(false);

  // const [themeLoading, setThemeLoading] = useState(false);

  const axios = useAxios();
  const unAuthAxios = axiosUnAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [pdfContent, setPdfContent] = useState<any>(null);
  const [agreementText, setAggrementText] = useState("");
  const { data: isTermsAcceptedData, isError: isTermsAcceptedDataError } =
    IsTermsAndConditionsAccepted(axios, la.name ? true : false);

  const { data: presignedUrlData, isFetching: themeLoading } =
    GetPresignedUrlData(axios, !termsAccepted || isOpenTermAndCondition);

  const signout = async () => {
    batch(() => {
      dispatch(clearUser());
      dispatch(clearDeckState());
      dispatch(queryActivePage({ pageName: "/", index: 0 }));
    });
    window.Intercom("shutdown");
    resetProjects();
    window.sessionStorage.removeItem("currentUser");
    queryClient.removeQueries(["datasets"]);
    history.push("/");
  };

  useEffect(() => {
    if (isTermsAcceptedData) {
      dispatch(
        setTermsAccepted(isTermsAcceptedData[0]?.terms_accepted === true)
      );
    }
    if (isTermsAcceptedDataError) {
      setTimeout(() => signout(), 2000);
    }
    //eslint-disable-next-line
  }, [isTermsAcceptedData, isTermsAcceptedDataError]);

  useEffect(() => {
    if (presignedUrlData) {
      (async () => {
        let pdfContentDataAggrement;
        let pdfContentTerms;
        setAggrementText(presignedUrlData.agreement_text);
        if (presignedUrlData.terms_and_condition_url) {
          pdfContentTerms = await unAuthAxios.get(
            presignedUrlData.terms_and_condition_url,
            {
              responseType: "blob",
            }
          );
        }
        if (presignedUrlData.data_agreement_url) {
          pdfContentDataAggrement = await unAuthAxios.get(
            presignedUrlData.data_agreement_url,
            {
              responseType: "blob",
            }
          );
        }
        const dataAgreementUrl =
          pdfContentDataAggrement?.data &&
          window.URL.createObjectURL(pdfContentDataAggrement?.data);
        const terms_and_condition_url =
          pdfContentTerms?.data &&
          window.URL.createObjectURL(pdfContentTerms?.data);
        if (terms_and_condition_url && dataAgreementUrl) {
          setPdfContent([terms_and_condition_url, dataAgreementUrl]);
        } else if (terms_and_condition_url) {
          setPdfContent([terms_and_condition_url]);
        } else if (dataAgreementUrl) {
          setPdfContent([dataAgreementUrl]);
        }
      })();
    }
    //eslint-disable-next-line
  }, [presignedUrlData]);

  const acceptTerms = async () => {
    const payload = { terms_accepted: true };
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_TERMS_API}`,
        payload
      );
      dispatch(setTermsAccepted(data?.message === "Terms Updated"));
    } catch (error) {
      errorPrompt({
        message: "We had a problem updating terms and conditions.",
        description: " Please try logging in to your account later",
      });

      setTimeout(() => signout(), 2000);
    }
  };

  if (themeLoading)
    return (
      <Spin
        size="large"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
    );

  return (
    <Modal
      open={!termsAccepted || isOpenTermAndCondition}
      title=""
      onCancel={() => dispatch(setIsTermAndConditionVisible(false))}
      centered
      width={820}
      footer={
        <Space
          direction="horizontal"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Checkbox
            disabled={termsAccepted}
            checked={agreeTnc || termsAccepted}
            onChange={() => setAgreeTnc((prev: any) => !prev)}
            style={{ textAlign: "left" }}
          >
            <Typography.Text strong>{agreementText}</Typography.Text>
          </Checkbox>
          <Button
            disabled={!agreeTnc}
            onClick={acceptTerms}
            style={{
              background: !agreeTnc
                ? "var(--light-shade)"
                : "var(--default-cta)",
              color: "var(--default-white)",
            }}
          >
            Agree to Terms of Service
          </Button>
        </Space>
      }
      bodyStyle={{ height: 400, overflowY: "scroll", paddingTop: 70 }}
      closable={termsAccepted}
      maskClosable={false}
    >
      <PDFViewer pdfContent={pdfContent} />
    </Modal>
  );
};

export default TermsAndConditions;